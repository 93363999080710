/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Custom Font */
@font-face {
  font-family: 'Clacon';
  src: url('./assets/fonts/clacon2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Apply the Custom Font Globally (Optional) */
body {
  font-family: 'Clacon', sans-serif;
  background-color: #2c2c2c;
}

/* Scanlines CSS */
.scanlines {
  overflow: hidden;
  position: relative;
}
.scanlines:before,
.scanlines:after {
  display: block;
  pointer-events: none;
  content: "";
  position: absolute;
}
.scanlines:before {
  width: 100%;
  height: 2px;
  z-index: 2147483649;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.75;
  animation: scanline 6s linear infinite;
}
.scanlines:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483648;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 0, 0, 0.3) 51%
  );
  background-size: 100% 4px;
  animation: scanlines 1s steps(60) infinite;
}

/* ANIMATE UNIQUE SCANLINE */
@keyframes scanline {
  0% {
    transform: translate3d(0, 200000%, 0);
  }
}
@keyframes scanlines {
  0% {
    background-position: 0 200000%;
  }
}

div.scanlines {
  z-index: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.tooltip-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.tooltip-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.tooltip-exit {
  opacity: 1;
  transform: translateY(0);
}
.tooltip-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

@keyframes ring {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(20deg); }
  20% { transform: rotate(-20deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-14deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(-10deg); }
  70% { transform: rotate(6deg); }
  80% { transform: rotate(-6deg); }
  90% { transform: rotate(1deg); }
  100% { transform: rotate(0deg); }
}

/* Custom Animation Class */
.ring-animation {
  animation: ring 0.5s ease-in-out 3; /* Runs 3 times */
}