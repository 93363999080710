/* src/toast.css */

/* Override the default toast container styles */
.custom-toast-container .Toastify__toast {
  font-family: 'Clacon', monospace; /* Apply the custom font */
  background-color: #2c2c2c; /* Match your dark gray background */
  border: 2px dotted rgb(207 255 4); /* Apply a 2px dotted border in lm-orange */
  color: #ffff00; /* lm-green text color */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Customize success and error toasts */
.Toastify__toast--success {
  background-color: #2c2c2c; /* Dark gray */
  border-left: 5px solid rgb(207 255 4); /* lm-orange border */
}

.Toastify__toast--error {
  background-color: #2c2c2c; /* Dark gray */
  border-left: 5px solid #ff3333; /* Red border for errors */
}

/* Customize the close button */
.Toastify__close-button {
  color: #ffff00; /* lm-yellow close button */
}

.Toastify__close-button:hover {
  color: rgb(207 255 4); /* lm-orange on hover */
}

/* Optional: Customize the progress bar */
.Toastify__progress-bar {
  background: rgb(207 255 4); /* lm-orange progress bar */
}
